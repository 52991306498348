<template>
  <div>
    <div class="title">
      <van-icon name="arrow-left" class="lefticon" @click="goBack()" />
      <p>常见问题</p>
    </div>
    <div class="container">
      <div class="main">
        <div class="matter-title">论文上传之后安全吗？</div>
        <div
          class="matter-container"
        >我们在论文检测的各个环节均采用加密传输，同时有严格的论文保密规定，对所有用户提交的送检文档仅做检测分析，绝不保留全文，承诺对用户送检的文档不做任何形式的收录和泄露。</div>
      </div>
      <div class="main">
        <div class="matter-title">检测需要多少时间？</div>
        <div class="matter-container">正常情况需要十分钟左右(知网系列需要2个小时，而且晚上10点以后提交的订单需要第二天早上9点以后才出结果)，高峰期会有延长。</div>
      </div>
      <div class="main">
        <div class="matter-title">检测结果准不准确？</div>
        <div
          class="matter-container"
        >我们的维普检测、万方检测、知网PMLC、知网VIP使用的都是跟学校同样的系统，一样的数据库、一样的算法，所以检测结果是与学校一致的。但要确保检测的内容和提交给学校的是一样的，而且要选择与学校相同的系统(假如在我们这用维普检测，然后学校是用知网，那检测结果肯定是不一样的)</div>
      </div>

      <div class="main">
        <div class="matter-title">提交以后能不能退款？</div>
        <div class="matter-container">此系统一旦提交，系统开始检测后，即产生消费，无法退款！</div>
      </div>
      <div class="main">
        <div class="matter-title">检测内容范围？</div>

        <div
          class="matter-container"
        >系统不检测文章中的封面、致谢、学校(需要替换成"X")等个人信息，请在提交前自己删除，若提交后由系统自动删除时出现的任何问题责任自负！</div>
      </div>

      <div class="main">
        <div class="matter-title">检测时作者需要填吗？</div>
        <div
          class="matter-container"
        >在提交检测的文章中，引用了一些内以前自己所写的内容并且被小论文系统文献库收录，需要在此次检测中排除这些；则会有“去除本人已发表文献复制比”的结果。</div>
      </div>
      <div class="main">
        <div class="matter-title">怎么联系人工客服？</div>

        <div class="matter-container">微信：cx5078，电话：400-823-8869</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.title {
  display: flex;
  background: #228ef7;
}
.lefticon {
  line-height: 0.8rem;
  padding: 0 0.3rem;
  color: #fff;
  font-size: 0.4rem;
}
.title p {
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
  flex: 0.85;
}
.container {
  padding: 0.3rem;
  background-color: #fff;
  line-height: 0.5rem;
}

.main {
  border-bottom: 1px solid #ccc;
  margin: 0.3rem 0;
}
.matter-title {
  font-size: 0.3rem;
  color: #ff3030;
}
.matter-container {
  font-size: 0.3rem;
  text-indent: 0.6rem;
  padding: 0.2rem 0;
}
</style>